import React, { useEffect } from 'react'
import { Typography, Box } from '@material-ui/core'
import Layout from '../components/Layout'

const NotFoundPage = () => {
  useEffect(() => {
    window.dataLayer.push({ page: null })
    window.dataLayer.push({
      event: 'page_view',
      page: {
        '@type': 'WebPage',
        breadcrumb: '404',
        id: '404',
        name: '404 Page',
        page_category: 'WebPage',
      },
    })
  }, [])
  return (
    <Layout>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        minHeight="calc(100vh)"
      >
        <Typography variant="h1">NOT FOUND</Typography>
        <Typography variant="body1">Still Rising.</Typography>
      </Box>
    </Layout>
  )
}

export default NotFoundPage
